$.fn.lib_reCaptcha = function(attr) {
    let elm = $(this);
    if(elm.length) {
        // $.importScript(cdnjs.recapcha.replace("{apikey}",elm.data(attr)), function(){
        //     grecaptcha.ready(function() {
        //         grecaptcha.execute(elm.data(attr), {action: 'form'}).then(function(token) {
        //             elm.find(`[name="gtoken"]`).val(token);
        //         });
        //     });
        // })
    }
};
